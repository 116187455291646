import React from 'react';
import { lazy } from 'react';
import Loadable from 'src/layout/full/loadable/Loadable';

const EmailLogs = Loadable(lazy(() => import('src/view/logs/Email')));
const LoginLogs = Loadable(lazy(() => import('src/view/logs/Login')));
const Outbox = Loadable(lazy(() => import('src/view/logs/Outbox')));
const Sendbox = Loadable(lazy(() => import('src/view/logs/Sendbox')));
const ShemaTaskFrequency = Loadable(lazy(() => import('src/view/logs/ShemaTaskFrequency')));
const CronJobs = Loadable(lazy(() => import('src/view/logs/CronJobs')));


// eslint-disable-next-line import/no-anonymous-default-export
export default {
  path: 'logs',
  children: [
    {
      path: 'email',
      element: <EmailLogs />,
    },
    { path: 'login', element: <LoginLogs /> },
    { path: 'cron-jobs', element: <CronJobs /> },

    { path: 'sendbox', element: <Sendbox /> },
    { path: 'outbox', element: <Outbox /> },

    {
      path: 'shema-task-frequency',
      children: [
        {
          path: '',
          element: <ShemaTaskFrequency />,
        },
        {
          path: ':TaskID',
          element: <ShemaTaskFrequency />,
        },
      ],
    },
  ],
};
