import React from 'react';
import { lazy } from 'react';
import Loadable from 'src/layout/full/loadable/Loadable';

//ppe routes
const PPE = Loadable(lazy(() => import('src/view/masters/ppe/index')));
const AddTraciPPE = Loadable(lazy(() => import('src/view/masters/ppe/AddPPE')));
const EditTraciPPE = Loadable(lazy(() => import('src/view/masters/ppe/EditPPE')));

//employee routes
const Employee = Loadable(lazy(() => import('src/view/masters/employee/index')));
const AddEmployee = Loadable(lazy(() => import('src/view/masters/employee/AddEmployee')));
const EditEmployee = Loadable(lazy(() => import('src/view/masters/employee/EditEmployee')));

//location routes
const Location = Loadable(lazy(() => import('src/view/masters/location/index')));
const AddLocation = Loadable(lazy(() => import('src/view/masters/location/AddLocation')));
const EditLocation = Loadable(lazy(() => import('src/view/masters/location/EditLocation')));

//hazard routes
const Hazard = Loadable(lazy(() => import('src/view/masters/hazard/index')));

//potential energy routes
const PotentialEnergy = Loadable(lazy(() => import('src/view/masters/potentialEnergy/index')));

//programe routes
const Program = Loadable(lazy(() => import('src/view/masters/program/index')));

//element routes
const Element = Loadable(lazy(() => import('src/view/masters/element/index')));

//regulation routes
const Regulation = Loadable(lazy(() => import('src/view/masters/regulation/index')));

//department routes
const Department = Loadable(lazy(() => import('src/view/masters/department/index')));

//state routes
const States = Loadable(lazy(() => import('src/view/masters/states/index')));

//risk control routes
const RiskControl = Loadable(lazy(() => import('src/view/masters/riskControl/index')));

//Hardware Tool Routes
const HardwareTool = Loadable(lazy(() => import('src/view/masters/hardwareTools/index')));

//MRA Hazard Tool Routes
const MRAHazard = Loadable(lazy(() => import('src/view/masters/mraHazard/index')));

//Waste Code Routes
const WasteCode = Loadable(lazy(() => import('src/view/masters/wasteCodes')));
const WasteCodeReorder = Loadable(
  lazy(() => import('src/view/masters/wasteCodes/WasteCodeReorder')),
);

//Waste Classification Routes
const WasteClassification = Loadable(lazy(() => import('src/view/masters/wasteClassifications')));

// Injury Types Routes
const InjuryTypes = Loadable(lazy(() => import('src/view/masters/injuryTypes')));

// Body Parts Routes
const BodyParts = Loadable(lazy(() => import('src/view/masters/bodyParts')));
// Employee Group
const EmpGroup = Loadable(lazy(() => import('src/view/masters/empGroup')));

// LMS

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  path: 'master',
  children: [
    {
      path: '',
      children: [
        {
          path: 'ppe',
          children: [
            {
              path: '',
              element: <PPE />,
            },
            {
              path: 'add',
              element: <AddTraciPPE />,
            },
            {
              path: 'edit/:PPEID',
              element: <EditTraciPPE />,
            },
          ],
        },
        {
          path: 'location',
          children: [
            {
              path: '',
              element: <Location />,
            },
            {
              path: 'add',
              element: <AddLocation />,
            },
            {
              path: 'edit/:LocationID',
              element: <EditLocation />,
            },
          ],
        },
        {
          path: 'employee',
          children: [
            {
              path: '',
              element: <Employee />,
            },
            {
              path: 'add',
              element: <AddEmployee />,
            },
            {
              path: 'edit/:EmpID',
              element: <EditEmployee />,
            },
          ],
        },
        {
          path: 'program',
          children: [
            {
              path: '',
              element: <Program />,
            },
          ],
        },
        {
          path: 'element',
          children: [
            {
              path: '',
              element: <Element />,
            },
          ],
        },
        {
          path: 'regulation',
          children: [
            {
              path: '',
              element: <Regulation />,
            },
          ],
        },
        {
          path: 'hazard',
          children: [
            {
              path: '',
              element: <Hazard />,
            },
          ],
        },
        {
          path: 'potential-energy',
          children: [
            {
              path: '',
              element: <PotentialEnergy />,
            },
          ],
        },
        {
          path: 'department',
          children: [
            {
              path: '',
              element: <Department />,
            },
          ],
        },
        {
          path: 'states',
          children: [
            {
              path: '',
              element: <States />,
            },
          ],
        },
        {
          path: 'risk-control',
          children: [
            {
              path: '',
              element: <RiskControl />,
            },
          ],
        },
        {
          path: 'hardwaretools',
          children: [
            {
              path: '',
              element: <HardwareTool />,
            },
          ],
        },
        {
          path: 'mra-hazard',
          children: [
            {
              path: '',
              element: <MRAHazard />,
            },
          ],
        },
        {
          path: 'waste-codes',
          children: [
            {
              path: '',
              element: <WasteCode />,
            },
            {
              path: 'reorder',
              element: <WasteCodeReorder />,
            },
          ],
        },
        {
          path: 'waste-classifications',
          children: [
            {
              path: '',
              element: <WasteClassification />,
            },
          ],
        },

        {
          path: 'injury-types',
          children: [
            {
              path: '',
              element: <InjuryTypes />,
            },
          ],
        },
        {
          path: 'body-parts',
          children: [
            {
              path: '',
              element: <BodyParts />,
            },
          ],
        },
        {
          path: 'employee-group',
          children: [
            {
              path: '',
              element: <EmpGroup />,
            },
          ],
        },
      ],
    },
  ],
};
