import React from 'react';
import { lazy } from 'react';
import Loadable from 'src/layout/full/loadable/Loadable';


//SHEA - masters routes
const Compliances = Loadable(lazy(() => import('src/view/shea/compliances')));
const ComplianceProgram = Loadable(
  lazy(() => import('src/view/shea/compliances/ComplianceProgram')),
);
const ComplianceProgramQuestion = Loadable(
  lazy(() => import('src/view/shea/compliances/ComplianceProQues')),
);
const ComplianceQuestionAdd = Loadable(
  lazy(() => import('src/view/shea/compliances/ComplianceQuestionAdd')),
);
const ComplianceQuestionEdit = Loadable(
  lazy(() => import('src/view/shea/compliances/ComplianceQuestionEdit')),
);

const Obligation = Loadable(lazy(() => import('src/view/shea/obligation')));
const Frequency = Loadable(lazy(() => import('src/view/shea/frequency')));

const ComplianceProgramSort = Loadable(lazy(() => import("src/view/shea/compliances/ComplianceProgramSort")));
const ComplianceProgramQuestionSort = Loadable(lazy(() => import('src/view/shea/compliances/ComplianceProQuesSort')));


// eslint-disable-next-line import/no-anonymous-default-export
export default {
  path: 'shea',
  children: [
    {
      path: '',
      children: [
        {
          path: 'compliances',
          children: [
            {
              path: '',
              element: <Compliances />,
            },
            {
              path: 'compliance-programs/:ComplianceID',
              children: [
                {
                  path: '',
                  element: <ComplianceProgram />,
                },
                {
                  path: 'sort',
                  element: <ComplianceProgramSort />,
                },
      
                {
                  path: 'compliance-programs-questions/:ComplianceProgramID',
                  children: [
                    {
                      path: '',
                      element: <ComplianceProgramQuestion />,
                    },
                    {
                      path:"sort",
                      element:<ComplianceProgramQuestionSort />
                  
                    },
                    {
                      path: 'add',
                      element: <ComplianceQuestionAdd />,
                    },
                    {
                      path: 'edit/:QuestionID',
                      element: <ComplianceQuestionEdit />,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: 'obligation',
          children: [
            {
              path: '',
              element: <Obligation />,
            },
          ],
        },
        {
          path: 'frequency',
          children: [
            {
              path: '',
              element: <Frequency />,
            },
          ],
        },
      ],
    },
  ],
};
