import React from 'react';
import { lazy } from 'react';
import Loadable from 'src/layout/full/loadable/Loadable';

//MOCA - Master Routes
const PSSR = Loadable(lazy(() => import('src/view/moca/pssrPrograms')));
const PSSRProgramQuestion = Loadable(lazy(() => import('src/view/moca/pssrPrograms/PSSRProgramsQues')));
const PSSRProgramQuestionAdd = Loadable(lazy(() => import('src/view/moca/pssrPrograms/PSSRProgramQuesAdd')));
const PSSRProgramQuestionEdit = Loadable(lazy(() => import('src/view/moca/pssrPrograms/PSSRProgramQuesEdit')));

const CRAPrograms = Loadable(lazy(() => import('src/view/moca/craPrograms')));
const CRAQuestion = Loadable(lazy(() => import('src/view/moca/craPrograms/CRAProgramQuestion')));
const CRAQuestionAdd =  Loadable(lazy(() => import('src/view/moca/craPrograms/CRAProgramQuestionAdd')));
const CRAQuestionEdit = Loadable(lazy(() => import('src/view/moca/craPrograms/CRAProgramQuestionEdit')));

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  path: 'moca',
  children: [
    {
      path: '',
      children: [
        {
          path: 'pssr',
          children: [
            {
              path: '',
              element: <PSSR />,
            },
            {
              path: 'pssr-programs-questions/:PSSRProgramID',
              element: <PSSRProgramQuestion />,
            },
            {
              path: 'pssr-programs-questions/:PSSRProgramID/add',
              element: <PSSRProgramQuestionAdd />,
            },
            {
              path: 'pssr-programs-questions/:PSSRProgramID/edit/:PSSRQuestionID',
              element: <PSSRProgramQuestionEdit />,
            },
          
          ],
        },
        {
          path: 'cra',
          children: [
            {
              path: '',
              element: <CRAPrograms />,
            },
            {
              path: 'cra-programs-questions/:CRAProgramID',
              element: <CRAQuestion />,
            },
            {
              path: 'cra-programs-questions/:CRAProgramID/add',
              element: <CRAQuestionAdd />,
            },
            {
              path: 'cra-programs-questions/:CRAProgramID/edit/:CRAQuestionID',
              element: <CRAQuestionEdit />,
            },
          
          ],
        },
      ],
    },
  ],
};
