import { lazy } from 'react';
import Loadable from 'src/layout/full/loadable/Loadable';

//TRACI - masters routes
const PPE = Loadable(lazy(() => import('src/view/traci/ppe')));
const AddPPE = Loadable(lazy(() => import('src/view/traci/ppe/AddPPE')));
const EditPPE = Loadable(lazy(() => import('src/view/traci/ppe/EditPPE')));

const Hazard = Loadable(lazy(() => import('src/view/traci/hazard')));
const PotentialEnergy = Loadable(lazy(() => import('src/view/traci/potentialEnergy')));
const HardwareTools = Loadable(lazy(() => import('src/view/traci/hardwareTools')));
const RiskControl = Loadable(lazy(() => import('src/view/traci/riskControl')));
const MRAHazardList = Loadable(lazy(() => import('src/view/traci/mraHazard')));
const AtmosphericHazardList = Loadable(lazy(() => import('src/view/traci/atmosphericHazard')));
const EngulfmentHazardList = Loadable(lazy(() => import('src/view/traci/engulfmentHazard')));
const HealthHazardList = Loadable(lazy(() => import('src/view/traci/healthHazard')));
const ConfigurationHazard = Loadable(lazy(() => import('src/view/traci/ConfigurationHazard')));
const EmergencyHazard = Loadable(lazy(() => import('src/view/traci/EmergencyHazard')));
const SafetyEquipment = Loadable(lazy(() => import('src/view/traci/safetyEquipment')));
const RescueEquipment = Loadable(lazy(() => import('src/view/traci/rescueEquipment')));

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  path: 'traci',
  children: [
    {
      path: '',
      children: [
        {
          path: 'hazard',
          element: <Hazard />,
        },
        {
          path: 'potential-energy',
          element: <PotentialEnergy />,
        },
        {
          path: 'hardware-tools',
          element: <HardwareTools />,
        },
        {
          path: 'risk-controls',
          element: <RiskControl />,
        },
        {
          path: 'mra-hazard-list',
          element: <MRAHazardList />,
        },
        {
          path: 'atmospheric-hazard-list',
          element: <AtmosphericHazardList />,
        },
        {
          path: 'engulfment-hazard-list',
          element: <EngulfmentHazardList />,
        },
        {
          path: 'health-hazard-list',
          element: <HealthHazardList />,
        },
        {
          path: 'health-hazard-list',
          element: <HealthHazardList />,
        },
        {
          path: 'config-hazard-list',
          element: <ConfigurationHazard />,
        },
        {
          path: 'emergency-equipment',
          element: <EmergencyHazard />,
        },
        {
          path: 'safety-equipment',
          element: <SafetyEquipment />,
        },
        {
          path: 'rescue-equipment',
          element: <RescueEquipment />,
        },

        {
          path: 'ppe',
          children: [
            {
              path: '',
              children: [
                {
                  path: '',
                  element: <PPE />,
                },
                {
                  path: 'add',
                  element: <AddPPE />,
                },
                {
                  path: 'edit/:PPEID',
                  element: <EditPPE />,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
