import React from 'react';
import { lazy } from 'react';
import Loadable from 'src/layout/full/loadable/Loadable';

const LMSCourse = Loadable(lazy(() => import('src/view/lms')));
const AddCourse = Loadable(lazy(() => import('src/view/lms/AddCourse')));
const EditCourse = Loadable(lazy(() => import('src/view/lms/EditCourse')));
const LmsCategory = Loadable(lazy(() => import('src/view/lms/lmsCategory')));
const CategoriesReorder = Loadable(
  lazy(() => import('src/view/lms/lmsCategory/CategoriesReorder')),
);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  path: 'lms',
  children: [
    {
      path: 'courses',
      children: [
        { path: '', element: <LMSCourse /> },
        { path: 'add', element: <AddCourse /> },
        { path: 'edit/:CourseID', element: <EditCourse /> },
      ],
    },
    {
      path: 'category',
      children: [
        { path: '', element: <LmsCategory /> },
        {
          path: 'reorder',
          element: <CategoriesReorder />,
        },
      ],
    },
  ],
};
