import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Box, Button, Dialog, DialogTitle, DialogActions, DialogContent, Typography, styled, useTheme } from '@mui/material';
import { resetNotification } from 'src/store/slice/app/AppSlice';

import { IconAlertTriangle } from '@tabler/icons-react';
import { IconCircleX } from '@tabler/icons';

const CustomDialogBox = styled(Dialog)(({ theme }) => ({
    '& .MuiTypography-root': {
        padding: theme.spacing(1),
    },
    '& .MuiDialogContent-root': {
        padding: theme.spacing(0),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function ErrorDialog() {
    const app = useSelector((state) => state.app);
    const dispatch = useDispatch();
    const theme = useTheme();
    const { open, message, variant } = app?.Notification || {
        open: false,
        message: '',
        variant: 'error',
        description: '',
    };
    if (!open) return <></>;
    return (
        <CustomDialogBox
            open={open}
            aria-labelledby="general-error-dialog"
            maxWidth="xs"
            fullWidth
        >
            <Box sx={{ margin: 2 }}>
                <Box sx={{ display: 'flex' }}>
                    <DialogContent>
                        <Typography
                            variant="h6"
                            fontWeight={600}
                            textAlign="center"
                            sx={{
                                backgroundColor: theme.palette.slate.main,
                                borderRadius: '5px',
                            }}
                        >
                            {variant === 'error' ? 'Error' : 'Warning'}
                        </Typography>

                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'start',
                                padding: '10px 0px',
                            }}
                        >
                            <DialogTitle id="alert-dialog-title">
                                {variant === 'error' ? (
                                    <IconCircleX fontSize={20} color="#e67d34" />
                                ) : (
                                    <IconAlertTriangle fontSize={20} color="#faad14" />
                                )}
                            </DialogTitle>
                            <DialogContent>
                                <Typography variant="subtitle2" textAlign="center">
                                    {message || 'Something Went Wrong'}
                                </Typography>

                            </DialogContent>
                        </Box>
                    </DialogContent>
                </Box>

                <DialogActions>
                    <Button
                        autoFocus
                        color="secondary"
                        variant="contained"
                        size="small"
                        sx={{
                            '&:hover': {
                                backgroundColor: theme.palette.gray.light,
                                color: theme.palette.secondary.main,
                            },
                        }}
                        onClick={() => {
                            dispatch(resetNotification());
                        }}
                    >
                        OK
                    </Button>
                </DialogActions>
            </Box>
        </CustomDialogBox>
    );
}
