import React from 'react';
import { lazy } from 'react';
import Loadable from 'src/layout/full/loadable/Loadable';


const Users = Loadable(lazy(() => import('src/view/users/AllUsers')));


// eslint-disable-next-line import/no-anonymous-default-export
export default {
  path: 'users',
  children: [
    {
      path: '',
      element: <Users />,
    },
  ],
};
