import React from 'react';
import { lazy } from 'react';
import Loadable from 'src/layout/full/loadable/Loadable';

//AMLA - masters routes
const CauseOfIncidence = Loadable(lazy(() => import('src/view/amla/causeOfIncidence')));
const RootCauseAnalysis = Loadable(lazy(() => import('src/view/amla/rootCauseAnalysis')));
const InvestigationQuestion = Loadable(lazy(() => import('src/view/amla/investigationQuestion')));
const RecordableIncident = Loadable(lazy(() => import('src/view/amla/recordableIncidents')));
const InjuryQuestions = Loadable(lazy(() => import('src/view/amla/injuryQuestions')));

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  path: 'amla',
  children: [
    {
      path: '',
      children: [
        {
          path: 'cause-of-incidence',
          element: <CauseOfIncidence />,
        },
        {
          path: 'root-cause-analysis',
          element: <RootCauseAnalysis />,
        },
        {
          path: 'investigation-question',
          element: <InvestigationQuestion />,
        },
        {
          path: 'recordable-incidents',
          element: <RecordableIncident />,
        },
        {
          path: 'injury-questions',
          element: <InjuryQuestions />,
        },

      ],
    },
  ],
};
