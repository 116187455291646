import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    PageLocation: null,
    ActivePageNumber:null,
    Notification:{
      open:false,
      message:"",
      variant:"error",
      description:""
    }
};

export const AppSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setPageLocation: (state, action) => {
      state.PageLocation = action.payload.PageLocation;
      state.ActivePageNumber = action.payload.ActivePageNumber;
    },
    resetPageLocation: (state, action) => {
      state.PageLocation = null;
      state.ActivePageNumber = null;
    },
    notify:(state , action) =>{
      state.Notification ={open:true , ...action.payload}
     
    }, 
    resetNotification:(state) =>{
      state.Notification = {
        open:false,
        message:"",
        variant:"error",
        description:""
      }
    }
    
  },
});

export const {
    setPageLocation,
    resetPageLocation,
    notify,
    resetNotification,
} = AppSlice.actions;

export default AppSlice.reducer;
